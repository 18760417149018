import React from 'react';
import { graphql } from 'gatsby';
import { get, isEmpty } from 'lodash';

import { Error404 } from 'components/error-404/Error404';

import { Image } from 'components/image/Image';

import { MetaContainer } from 'containers/meta/MetaContainer';

interface IProps {
  data: any;
}

const Error404Page = (props: IProps) => {
  const { data } = props;
  const pageData = get(data, 'prismic.allError_404s.edges.0.node', {});

  const image = !isEmpty(pageData.image.x1.url) ? (
  <Image src={pageData.image.x1.url} src2x={pageData.image.x2.url} alt="" />
   ) : undefined;

  return (
    <>
      <MetaContainer data={pageData} />

      <Error404 image={image} />
    </>
  );
};

export const query = graphql`
  query {
    prismic {
      allError_404s(lang: "en-us") {
        edges {
          node {
            _meta {
              uid
              lang
              alternateLanguages {
                id
                lang
              }
            }
            title
            description
            image
            seo_title
            seo_description
            seo_image
          }
        }
      }
    }
  }
`;

export default Error404Page;
