import React, { cloneElement, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { UIContext } from 'context/ui';
import { Container } from 'components/layout/Container';
import { ScrollIndicator } from 'components/scroll-indicator/ScrollIndicator';

import s from './Error404.scss';

interface IProps {
  image?: React.ReactElement<any>;
}

export const Error404 = ({ image }: IProps) => {
  const { setHeaderTheme } = useContext<any>(UIContext);

  useEffect(() => { setHeaderTheme('light'); }, []);

  return (
    <div className={s('error404')}>
      <div className={s.error404__inner}>
        {image && cloneElement(image, {className: s.error404__image})}
        <Container>
          <div className={s.error404__content}>
            <h1 className={s.error404__heading}><FormattedMessage id="404-message" /></h1>
            <div className={s.error404__kicker}>Error 404</div>
          </div>
        </Container>
      </div>
      <ScrollIndicator />
    </div>
  );
};
